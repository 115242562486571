// Utilities
import { defineStore } from 'pinia'
import AppConfig from '@/appConfig'

export const useAppStore = defineStore('app', {
  state: () => ({
    idvessel: -1,
    vesselName: '-',
    helpWindowID: null,
  }),
  actions: {
    openHelp(helpfile) {
      // console.log('help doc', helpfile)
      const src = `${AppConfig.helpPath + helpfile}.html`
      const name = 'TrammoNoonHelpWin'
      const params =
        'left=200,top=100,screenX=200,width=800,height=800, titlebar=no, toolbar=no, menubar=no, location=no, resizable=yes, scrollbars=yes, status=no'
      this.helpWindowID = window.open(src, name, params)
    },
    closeHelp() {
      if (this.helpWindowID) this.helpWindowID.close()
    },

    clear() {
      this.$reset()
    },
  },
  persist: true,
})
